var exports = {};

function _assertThisInitialized(self) {
  if (self === void 0) {
    throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
  }

  return self;
}

exports = _assertThisInitialized, exports.__esModule = true, exports["default"] = exports;
export default exports;
export const __esModule = exports.__esModule;